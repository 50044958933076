@import '~@aws-amplify/ui-angular/theme.css';


  .cursor-pointer {
    cursor: pointer;
  }

.navbar.topnav-menu {
     height: unset;
 }

.navbar-logo {
    max-height: 150px;

}

.amplify-heading  {
  color: #8c52ff;
}

@media (max-width: 991.98px) {
  .navbar-logo {
    max-height: 90px;
}

  .navbar-collapse {
    max-height: 50vh;
    min-height: 50vh;
    overflow-y: auto;

}


}



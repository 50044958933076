.jarallax {
    position: relative;
    // z-index: 0;
}

.jarallax {
    // position: absolute;
    object-fit: cover;
    /* support for plugin https://github.com/bfred-it/object-fit-images */
    font-family: "object-fit: cover;";
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    // transform: inherit !important;
}

//
// accordions.scss
//

.custom-accordionwitharrow {
    .card {
        + .card {
            margin-top: 0.5rem;
        }
        .card-header {
            background-color: transparent;
            position: relative;
        }
    }

    .accordion-arrow {
        position: absolute;
        right: 1.25rem;
    }

    a {
        &.collapsed {
            .accordion-arrow {
                transform: rotate(-90deg);
            }
        }
    }
}

// Custom Accordion

.custom-accordion {
    .card {
        overflow: visible;
        border-color: transparent !important;
        border-left: 2px dashed $gray-300 !important;
        box-shadow: none;
        padding-left: 40px;

        .card-header {
            border-radius: 7px !important;

            &:before {
                content: "";
                position: absolute;
                left: 6px;
                top: 22px;
                width: 42px;
                height: 2px;
                background: $gray-300;
            }
        }

        .icon {
            position: absolute;
            left: -18px;
            top: -2px;
        }
    }
}

.accordion {
    .accordion-item {
        .accordion-header {
            .accordion-button {
                padding: 0.75rem 1.5rem !important;
                color: $dark;
                box-shadow: none !important;
                &:after {
                    background-image: none;
                }
                &:not(.collapsed) {
                    color: inherit;
                    background-color: inherit;
                }
                &.collapsed {
                    .accordion-arrow {
                        transform: rotate(-90deg);
                    }
                }
            }
        }
        .accordion-body {
            padding: 0 !important;
        }
    }
}
